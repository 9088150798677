import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/ob/interview_iwamoto.png';
import interview from '../images/ob/interview_kenta.jpg';
import interviewphoto1 from '../images/ob/interview_photo1_kenta.jpg';
import interviewphoto2 from '../images/ob/interview_photo2_kenta.jpg';
// import * as ROUTES from '../src/constants/routes';

// <div className="ob-top-align">
// <img className="ob-top-info" alt="sakai" src={toboriTop} />
// </div>
class KentaPage extends React.Component {

  render() {
    return (
      <Fragment>
      <title>OBインタビュー　ウィタカ ケンタ|國學院大學久我山高等学校　バスケットボール部</title>
        <h2 className="content-title-black-ob">OBインタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">岩本陸</h4>
          <div className="mng-text">
          本日は、お忙しい中、インタビューに応じて下さり、ありがとうございます。
國學院久我山高校バスケ部の岩本と申します。本日は、どうぞよろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <h4 className="vertical-timeline-element-subtitle">OB ウィタカ ケンタ</h4>
          <div className="ob-text">
            よろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
            まず、自己紹介からお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <div className="ob-text">
            はい、熊本ヴォルターズ27番のウィタカケンタです。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
ありがとうございます。では、早速、國學院久我山への入学理由を教えて下さい。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <div className="ob-text">
          自分の場合は、中学校が勉強を重視する進学校だったので、実際バスケ自体は趣味程度でやっていて、続けるつもりもそんなに無かったんです。ある時、うちの中学校の女子バスケ部が久我山中の女子バスケ部と試合をした際に、応援に行き、その時、久我山女子バスケ部の監督が、「応援席に大きい子がいる！」ってのを、酒井監督に伝えてくれたんです。それがきっかけで、酒井監督から連絡を頂いて、そういう強い学校でできるチャンスがあれば、せっかく身長もあるし、これからもバスケ続けていこうかなっていう、最初は、ほんとに軽い気持ちじゃないですけど、そういうきっかけがあって、國學院久我山高校に進学しました。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。では、中学の時はバスケを本気でやろうと思っていなかったところから、全国レベルである國學院久我山高校バスケ部に進学したことで、いろんな苦労があったと思うんですけど、どんな苦労がありましたか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <div className="ob-text">
          そもそも、勉強の面でも中学校は勉強を結構やっていた方なんですけど、授業が英語よりだったり、そんなに日本語で勉強しなかったので、全部が全部、日本の学校っていう環境自体も初めてだったんで、まずそこで戸惑いがありました！また、日本の体育会系の部分は初めてだったので、先輩後輩の部分であったりとかは、最初は馴染むまではすごい苦労しました。やっぱりその練習の部分においても、他の強豪校に比べると学校の兼ね合いもあって練習時間が短いとは思うんですけど、中学の時は練習をそこまでハードにやってなかったので、そういう部分でも、高いレベルに慣れるのには時間がかかったのかなって思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          では、その勉強においても、練習においても、高いレベルに慣れる為に、工夫したことなどはありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <div className="ob-text">
          そうですね、自分の性格的に、人に言われて何かやるっていうのが好きじゃないので、だったら言われないレベルになればいいかなっていう考えがありました。そういう点においては、もちろん上手くなったりして周りから文句言われないってのもそうですけど、頭を使ってある意味ずる賢さっていうか、こういう風にしとけば、先生から指摘されないだろうとか、先輩達からは、見られるだろうみたいな、そういう部分で、上手く自分の立ち位置を立ち回りするっていう部分では、その工夫が今でも活きているかなって思います。
        </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obImg" alt="sakai" src={interviewphoto1} />
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          人に言われないようにする、言われる前にやってしまったりだとか、そういう工夫が大事だったということですね。ありがとうございます。では、次の質問に移らせて頂きます。
          國學院久我山高校で過ごしていて、何か印象的だったエピソードはありますか？

          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <div className="ob-text">
          高校の1年目は中3の時に前十字靭帯を切ったので、リハビリで1年間プレーをしてなくて、森本さん(当時のトレーナーの方)と結構トレーニングしたのは、思い出深い所があります。その中で自分がやらかしたエピソードとしては、練習中のトレーニングで、やっぱり久我山って授業も長いし、やることもいっぱいあるので、睡魔が襲ってきて、トレーニングルームでレッグカールをしていたら、うつ伏せのまま気づいたらウトウトしてしまい、、（笑）そこに透さん（アシスタントコーチ）が来て、「ウィタカ！寝てんじゃねぇぞ！」って叩き起こされたのは、今となってはいい思い出ですね(笑)
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          それはヒヤリとしますね！笑
勉強でもやることが多く、部活もあり、時間があまり無いと思うんですけど、そういう時に、勉強と部活の両立の為に意識していた事や行動はありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <div className="ob-text">
          そうですね。自分の場合は長い時間集中できるタイプでは無いので、バスケも勉強もそうですけど、バスケはなるべく久我山の場合は練習時間が短いっていうのもあって、練習中にどれだけ、チームとしてどういうバスケがしたいかっていうのを意識して、練習していましたね。勉強も一緒で帰宅後はあまりと勉強しないなって、自分で思っていたので、帰りにそのまま図書館に寄って、図書館の閉館時間まで、勉強してから家に帰ってましたね。テスト期間とかは特に。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。短い時間でしっかりと集中して物事に取り組むことを意識されていたんですね！では、次の質問に移らせて頂きます。國學院久我山高校を卒業後、青山学院大学に入学した理由やきっかけを教えて下さい。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <div className="ob-text">
          ありがたいことに、いくつかの大学からオファーを頂いていたんですけど、高校2年生の時に2度目の前十字を断裂していたこともあって、そのまま1年間切れたままプレーしてたんで、それを大学に入るときにどうするかというのを、行く先々の大学でリハビリの環境などを聞いていました。その中で青学は、トレーナーの方も日本代表の経験がある方で、トレーニング環境だったり、練習環境が一番整っていると感じたことがあったので、青学を選びました。その結果、大学一年時はリハビリに専念していましたが、最終的にはフィジカルの面だったり、今でも活きている部分がすごいあるので、青学を選んで正解だったなと思います！あとは、チームに自分の代に誰が来るのかっていうリクルートを聞いたときに、月刊バスケットボールに載るような選手たちが、自分の代で入ってくるという情報を聞いたので、その中で今、新潟アルビレックスにいる納見選手とか、琉球ゴールデンキングスにいるナナーダニエル弾選手とか、高校日本代表に入っていたようなメンバーが、同じ大学に来るというのにも惹かれて、青学を選びました。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obImg" alt="sakai" src={interviewphoto2} />
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          受験に向けて特別に勉強したことはありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <div className="ob-text">
          スポーツ推薦だったので、受験にむけては特に勉強はしなかったのですが、学校の勉強はしっかりと行っていました。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。では、今から國學院久我山高校に入るつもりの中学生の中にも、将来、Bリーガーになりたい！と思っている人はたくさんいると思うのですが、Bリーガーになるために必要なことはなんでしょうか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <div className="ob-text">
          自分がBリーグでやりたい！という気持ちを、いかにキープできるかってことだと思います。例えば、スキルがめちゃくちゃある子でも、スキル以外の面とかで、壁にぶつかった時に、せっかく上手なのに辞めていってしまった人が知り合いや友達に何人かいるので、どれだけBリーグでプレーしたい！この先バスケで生活していきたい！っていう気持ちの部分が一番大きいかなって思いますね。その気持ちさえあれば、そこからの工夫だったり、スキルだったりとかは、後から自分の工夫次第、努力次第でいくらでもついてくる物だと思うので。その気持ちの面から、自分が大事にしているのは、やりたいことと、やらなきゃいけないことの優先順位を自分の中で決めることです。例えば、誰でもバスケをしている中で監督と意見が合わないとかチームと意見が合わないとかあると思うんですけど、やりたいという自分の感情を押し出してしまうのか、それとも、今はこう振る舞ったり、こういうプレーをしたほうが、後々の評価に繋がるのかっていう部分では、やらなきゃいけないこと、自分の役割っていう部分を理解していくっていうことが、自分の評価に繋がっていくと思います。そういうスマートにやる部分っていうのは社会人になってから、バスケ以外の面でも使えるかなと思うので、自分の最終的な目標、Bリーガーっていう目標に行くための、近道を上手いこと進んでいくというのは、すごい大事かなって思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。では、最後に國學院久我山高校に入学希望のある中学生とその保護者に向けてメッセージをお願いします。　
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <div className="ob-text">
          國學院久我山高校に入学希望のある皆さん。その時点でいい学校を選んでいると僕自身は思います。結局どこの学校に行こうと、現代のバスケットであれば、やることだったり結構同じかなって思うんですけど、久我山に来ればさっき言ったディフェンスの面だったり、人間性の部分であったり、久我山でしか味わえない部分がたくさんあると思います。そういう部分を大事にしたい人にとっては、すごい良い学校だと思いますし、久我山に来て自分自身成長出来た部分がたくさんあると思うので、久我山に入学して三年間バスケットを続けていけば、その先自分自身の武器となるものを得られる三年間になると思うので、ぜひ久我山高校を選んで頂けたらと思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          本日はお忙しい中ありがとうございました。これからも久我山バスケ部の応援をよろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={interview} />}
        >
          <div className="ob-text">
          はい。ありがとうございました。
        </div>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <KentaPage />
  </Layout>
);
